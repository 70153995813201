import type { Action } from 'svelte/action';

interface InfiniteScrollParams {
    threshold?: number;
    callback: () => void;
}

export const infiniteScroll: Action<HTMLElement, InfiniteScrollParams> = (node, params) => {
    const threshold = params?.threshold ?? 100;

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const atBottom = scrollTop + clientHeight >= scrollHeight - threshold;

        if (atBottom && params?.callback) {
            params.callback();
        }
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener('scroll', throttledHandleScroll);

    return {
        update(newParams: InfiniteScrollParams) {
            params = newParams;
        },
        destroy() {
            window.removeEventListener('scroll', throttledHandleScroll);
        }
    };
};

function throttle(func: Function, limit: number) {
    let lastFunc: number;
    let lastRan: number;
    return function (this: any, ...args: any[]) {
        const context = this;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = window.setTimeout(
                () => {
                    if (Date.now() - lastRan >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                },
                limit - (Date.now() - lastRan)
            );
        }
    };
}
